.App {
  text-align: center;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.carImg {
  border-radius: 50px;
  border: 1px solid black;
  padding: 0%;
  box-shadow: 0px 0px 3px 0px black;
}

@media only screen and (max-width: 600px) {
  .navLogo {
    width: 40% !important
  }
  .navText {
    font-size: 80% !important;
  }
}

.bg {
  background: rgba(46, 204, 64, 0.03);
  backdrop-filter: blur(10px);
}

/* Add some basic styles for the container */
.search-product-list {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping of items */
  justify-content: center; /* Center the products */
  gap: 20px; /* Space between items */
  padding: 20px;
}

/* Style each product tile */
.search-product-tile {
  display: block;
  width: 250px; /* Set a fixed width for the product tile */
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  text-decoration: none;
  padding: 15px;
  transition: transform 0.3s, box-shadow 0.3s ease;
  overflow: hidden; /* Prevent content from overflowing */
}

/* Hover effect for the product tile */
.search-product-tile:hover {
  transform: translateY(-10px); /* Elevate the tile on hover */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
}

/* Style for the product image */
.search-product-image {
  width: 100%;
  height: 180px;
  object-fit: contain;
  border-radius: 8px;
}

/* Style the product title */
.search-product-title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  color: #333;
}

/* Style the product price */
.search-product-price {
  font-size: 16px;
  color: #28a745; /* Green color for price */
  margin-top: 5px;
  font-weight: bold;
}

/* Responsive: Make tiles smaller on mobile screens */
@media (max-width: 768px) {
  .search-product-tile {
    width: 100%; /* Full width for smaller screens */
  }
}

#search-title {
  margin-left: 1.5%;
}

.search-loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 50vh;  /* Centers the content vertically within the viewport */
  text-align: center;  /* Optional: Centers the text */
}
